import React, { useState, useEffect } from "react";
import { Box, Typography, TextField, InputLabel, MenuItem, FormControl, Select, Tooltip } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info';
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import 'animate.css'
import axios from 'axios';

// const direccionConsultas = 'http://localhost:5000/api'
const direccionConsultas = 'https://api-mapath.conatel.gob.hn/api'


const OperadoresActivos = () => {
    const [listadoOperadores, setListadoOperadores] = useState([])
    const [filtroSelect, setFiltroSelect] = useState('');
    const [filtroTexto, setFiltroTexto] = useState('');

    const handleFiltroSelectChange = (event) => {
        setFiltroSelect(event.target.value);
    };

    const handleFiltroTextoChange = (event) => {
        setFiltroTexto(event.target.value);
    };

    const columns = [
        {
            field: 'Operador',
            headerName: 'Nombre de empresa',
            flex: 1,
            headerAlign: 'center',
            align: 'left',
            className: 'Operador',
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'Denominacion',
            headerName: 'Nombre Comercial',
            flex: 1,
            headerAlign: 'center',
            align: 'left',
            className: 'Operador',
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'Nombre',
            headerName: 'Servicio',
            flex: 1,
            headerAlign: 'center',
            align: 'left',
            className: 'Nombre',
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                const currentRow = params.row;
                switch (currentRow.Nombre) {
                    case "Servicio de Internet o Acceso a Redes Informáticas": return ("Internet");
                    case "Servicio Televisión por Suscripción por Cable": return ("Televisión por cable");

                    default: return " ";
                }
            },
        }
    ]

    const ListadoOperadores = async () => {
        const Datos = await axios.post(direccionConsultas + '/ListadoOperadores')
        setListadoOperadores(Datos.data)
    }

    useEffect(() => {
        ListadoOperadores();
    }, [])


    const filteredRows = listadoOperadores.filter(
        (row) =>
            (filtroSelect === '' || row.Nombre.toString() === filtroSelect) &&
            (filtroTexto === '' ||
                (row.Operador && row.Operador.toLowerCase().includes(filtroTexto.toLowerCase())) ||
                (row.Denominacion && row.Denominacion.toLowerCase().includes(filtroTexto.toLowerCase()))
            )
    );

    return (
        <>
            <Box padding={2} display="grid" height="700px" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" alignItems="center"  >
                <Box sx={{ gridColumn: { md: "span 12", xs: "span 12" }, boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)", backgroundColor: "#ffff", borderRadius: "10px", height: "100%" }} >
                    <div style={{ width: '100%', height: '70%', borderRadius: "10px" }}>
                        <Box sx={{ backgroundColor: "#DB473C", padding: 1 }} display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" alignItems="center"  >
                            <Box sx={{ gridColumn: "span 12", textAlign: "center" }} >
                                <Typography color="white" fontSize={25} fontFamily="Anton" >VERIFICA TU OPERADOR</Typography>
                            </Box>
                        </Box>
                        <Box padding={1} display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" alignItems="center"  >
                            <Box sx={{ gridColumn: { md: "span 3", xs: "span 6" }, textAlign: "center" }} >
                                <FormControl size="small" style={{ marginBottom: 10 }} fullWidth variant="standard">
                                    <InputLabel id="servicio-label">Filtrar por servicio</InputLabel>
                                    <Select
                                        labelId="servicio-label"
                                        id="servicio-select"
                                        value={filtroSelect}
                                        onChange={handleFiltroSelectChange}
                                    >
                                        <MenuItem value="">Todos</MenuItem>
                                        <MenuItem value="Servicio Televisión por Suscripción por Cable">Televisión por Cable</MenuItem>
                                        <MenuItem value="Servicio de Internet o Acceso a Redes Informáticas">Internet</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box sx={{ gridColumn: { md: "span 3", xs: "span 6" }, textAlign: "center" }} >
                                <TextField
                                    fullWidth
                                    label={
                                        <Box display="flex" alignItems="center">
                                            Filtrar datos
                                            <Tooltip title="Filtre por operador o nombre comercial">
                                                <InfoIcon style={{ marginLeft: 4 }} color="action" />
                                            </Tooltip>
                                        </Box>
                                    }
                                    value={filtroTexto}
                                    onChange={handleFiltroTextoChange}
                                    variant="standard"
                                    size="small"
                                    style={{ marginBottom: 10 }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Box>
                        </Box>
                        <Box padding={1} display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" alignItems="center"  >
                            <Box sx={{ gridColumn: "span 12", textAlign: "center" }} >
                                <DataGrid
                                    rowHeight={20}
                                    disableRowSelectionOnClick={true}
                                    rows={filteredRows}
                                    columns={columns}
                                    getRowId={rows => rows.id}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 20,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[20]}
                                    components={{
                                        Toolbar: GridToolbar, // Utiliza el toolbar predeterminado de Material-UI
                                    }}
                                    localeText={{ toolbarFilters: 'Buscar', toolbarDensity: 'Vista', noRowsLabel: "" }}
                                    sx={{
                                        '.MuiDataGrid-columnHeaderTitle': {
                                            fontWeight: 'bold',
                                          },
                                      }}
                                />
                            </Box>
                        </Box>
                        {
                            filteredRows.length === 0 ?
                                <Box padding={1} display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" alignItems="center"  >
                                    <Box sx={{ gridColumn: "span 12", textAlign: "center" }} >
                                       <span style={{fontSize: "20px"}}>Este operador no está registrado ante CONATEL.</span> <br />
                                        Llama gratis al 185 para reportar a este operador irregular o repórtalo al correo: proteccionusuario@conatel.gob.hn<br />
                                        <span style={{fontSize: "20px"}}>¡COMBATAMOS LA PIRATERIA!</span>
                                    </Box>
                                </Box> :
                                <></>
                        }
                    </div>
                </Box>
            </Box>
        </>
    )
}

export default OperadoresActivos