import { Box, Button, MenuItem, Menu, Avatar, Stack, Tooltip } from "@mui/material";
import React, { useState, useEffect  } from "react";
import Logo1 from '../img/LogoNuevo.jpg'
import { useNavigate, useLocation  } from 'react-router-dom';

const Topbar = () => {
  
  const location = useLocation();
  const navigate = useNavigate();
  const [ubicacion, setUbicacion] = useState(location.pathname)
  const [selectedButton, setSelectedButton] = useState(null);

  useEffect(() => {
    setUbicacion(location.pathname);
  }, [location]);

  useEffect(() => {
    // Simula el clic cuando se monta el componente
    if (ubicacion === "/OperadoresActivos") {
      const button = document.getElementById('BtnActivos');
      if (button) {
        button.click();
      }
    }
    if (ubicacion === "/AudioVisual") {
      const button = document.getElementById('BtnAudioVisual');
      if (button) {
        button.click();
      }
    }
  }, [ubicacion]);

  const click1 = (buttonIndex) => {
    setSelectedButton(buttonIndex);
    navigate('/OperadoresActivos')
    setAnchorEl(null);
  };
  const click2 = (buttonIndex) => {
    setSelectedButton(buttonIndex);
    navigate('/Television')
    setAnchorEl(null);
  };
  const click3 = (buttonIndex) => {
    setSelectedButton(buttonIndex);
    navigate('/AudioVisual')
    setAnchorEl(null);
  };

  const click4 = (buttonIndex) => {
    setSelectedButton(buttonIndex);
    navigate('/Zonas')
    setAnchorEl(null);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const buttonStyle = (buttonIndex) => ({
    marginRight: '5px',
    whiteSpace: 'nowrap',
    color: selectedButton === buttonIndex ? '#FFFFFF' : '#DB473C',
    borderColor: '#DB473C',
    backgroundColor: selectedButton === buttonIndex ? '#DB473C' : '#FFFFFF',
  });

  return (
    // <Box sx={{ background: '#949699' }} display="flex" justifyContent="space-between" p={0.5}>
    <Box sx={{ background: '#e5e5e5' }} display="flex" justifyContent="space-between" p={0.5}>
      {/* SEARCH BAR */}
      <Box
        display="flex"
        borderRadius="3px"
        padding={0.5}
      >

      </Box>
      <Box
        display="flex"
        borderRadius="3px"
        justifyContent="left"
        alignItems="center"
      >
        <Stack direction="row" spacing={2} ><Avatar variant="square" sx={{ verticalAlign: 'middle', width: { md: "25%", xs: "50%" }, height: 'auto' }} src={Logo1} /></Stack>
      </Box>

      <Box sx={{ display: { xs: 'none', md: 'flex' } }} alignItems="center">
        <Button id="BtnActivos" onClick={() => click1(1)} size="small" variant="outlined" style={buttonStyle(1)}>VERIFICA TU OPERADOR</Button >
        <Button onClick={() => click2(2)} size="small" variant="outlined" style={buttonStyle(2)}>OPERADORES DE TELEVISIÓN</Button >
        <Button id="BtnAudioVisual" onClick={() => click3(3)} size="small" variant="outlined" style={buttonStyle(3)}>OPERADORES AUDIOVISUALES AUTORIZADOS</Button >
       <Tooltip title="Verifica la zona de radiodifusión de corresponde a tu municipio."> <Button onClick={() => click4(4)} size="small" variant="outlined" style={buttonStyle(4)}>ZONAS RADIODIFUSIÓN</Button ></Tooltip>
      </Box>
      <Box sx={{ display: { xs: 'flex', md: 'none' } }} alignItems="center" width='1000px' >
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          variant="outlined"
        >
          CONSULTAS
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={click1} value="">VERIFICA TU OPERADOR</MenuItem>
          <MenuItem onClick={click2} value="">OPERADORES DE TELEVISIÓN</MenuItem>
          <MenuItem onClick={click3} value="">OPERADORES AUDIOVISUALES</MenuItem>
          <MenuItem onClick={click4} value="">ZONAS RADIODIFUSIÓN</MenuItem>
        </Menu>
      </Box>

    </Box>
  );
};

export default Topbar;