import React, { useState, useEffect } from "react";
import { Box, Typography, InputLabel, MenuItem, FormControl, Select, Avatar, Stack } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import 'animate.css'
import axios from 'axios';
import zona1 from '../img/1.png'
import zona2 from '../img/2.png'
import zona3 from '../img/3.png'
import zona4 from '../img/4.png'
import zona5 from '../img/5.png'
import zona6 from '../img/6.png'
import zona7 from '../img/7.png'
import zona8 from '../img/8.png'
import zona9 from '../img/9.png'
import zona10 from '../img/10.png'


const direccionConsultas = 'https://api-mapath.conatel.gob.hn/api'

const ZonasRadiodifusion = () =>{
    const [listadoZonas, setListadoZonas] = useState([])
    const [municipios, setMunicipios] = useState([])
    const [filtroSelect, setFiltroSelect] = useState('');
    const [filtroSelect2, setFiltroSelect2] = useState('');
    const [filtroSelect3, setFiltroSelect3] = useState('');

    const handleFiltroSelectChange = (event) => {
        setFiltroSelect(event.target.value);
        if(event.target.value === "TODOS"){
            TodosMunicipios()
            setFiltroSelect('')
            setFiltroSelect2('')
            setFiltroSelect3('')
        }
    };

    const handleFiltroSelectChange2 = (event) => {
        setFiltroSelect2(event.target.value);
    };

    const handleFiltroSelectChange3 = (event) => {
        setFiltroSelect3(event.target.value);
    };

    const TodosMunicipios = async () => {
        const values ={
            'depto': filtroSelect
        }
        const Datos = await axios.post(direccionConsultas + '/TodosMunicipios', values)
        setMunicipios(Datos.data)
    }

    const columns = [
        {
            field: 'Depto',
            headerName: 'Departamento',
            flex: 1,
            headerAlign: 'center',
            align: 'left',
            className: 'Departamento',
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'Municipio',
            headerName: 'Municipio',
            flex: 1,
            headerAlign: 'center',
            align: 'left',
            className: 'Municipio',
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'Zona',
            headerName: 'Zona de Radiodifusión',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            className: 'Zona',
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                const currentRow = params.row;
                switch(currentRow.Zona){
                    case 1:  return (<Stack direction="row" spacing={2} justifyContent="center"><Avatar  sx={{verticalAlign: 'middle', width: { md: "4%", xs: "15%" }, height: 'auto'}} src={zona1} /></Stack>);
                    case 2:  return (<Stack direction="row" spacing={2} justifyContent="center"><Avatar  sx={{verticalAlign: 'middle', width: { md: "4%", xs: "15%" }, height: 'auto'}} src={zona2} /></Stack>);
                    case 3:  return (<Stack direction="row" spacing={2} justifyContent="center"><Avatar  sx={{verticalAlign: 'middle', width: { md: "4%", xs: "15%" }, height: 'auto'}} src={zona3} /></Stack>);
                    case 4:  return (<Stack direction="row" spacing={2} justifyContent="center"><Avatar  sx={{verticalAlign: 'middle', width: { md: "4%", xs: "15%" }, height: 'auto'}} src={zona4} /></Stack>);
                    case 5:  return (<Stack direction="row" spacing={2} justifyContent="center"><Avatar  sx={{verticalAlign: 'middle', width: { md: "4%", xs: "15%" }, height: 'auto'}} src={zona5} /></Stack>);
                    case 6:  return (<Stack direction="row" spacing={2} justifyContent="center"><Avatar  sx={{verticalAlign: 'middle', width: { md: "4%", xs: "15%" }, height: 'auto'}} src={zona6} /></Stack>);
                    case 7:  return (<Stack direction="row" spacing={2} justifyContent="center"><Avatar  sx={{verticalAlign: 'middle', width: { md: "4%", xs: "15%" }, height: 'auto'}} src={zona7} /></Stack>);
                    case 8:  return (<Stack direction="row" spacing={2} justifyContent="center"><Avatar  sx={{verticalAlign: 'middle', width: { md: "4%", xs: "15%" }, height: 'auto'}} src={zona8} /></Stack>);
                    case 9:  return (<Stack direction="row" spacing={2} justifyContent="center"><Avatar  sx={{verticalAlign: 'middle', width: { md: "4%", xs: "15%" }, height: 'auto'}} src={zona9} /></Stack>);
                    case 10:  return (<Stack direction="row" spacing={2} justifyContent="center"><Avatar  sx={{verticalAlign: 'middle', width: { md: "4%", xs: "15%" }, height: 'auto'}} src={zona10} /></Stack>);
                    default : return " ";
                }
            },
        }
    ]

    const ListadoZonasRadiodifusion = async () => {
        const Datos = await axios.post(direccionConsultas + '/ListadoZonasRadiodifusion')
        setListadoZonas(Datos.data)
    }

    useEffect(() => {
        ListadoZonasRadiodifusion();
    }, [])

    useEffect(() => {

        if (filtroSelect !== ""){

        const ListadoMunicipios = async () => {
            const values ={
                'depto': filtroSelect
            }
            const Datos = await axios.post(direccionConsultas + '/ListadoMunicipios', values)
            setMunicipios(Datos.data)
        }

        ListadoMunicipios();
    }
    }, [filtroSelect])

    let filteredRows = listadoZonas.filter(
        (row) =>
            (filtroSelect === '' || row.Depto.toString() === filtroSelect) &&
            (filtroSelect2 === '' || row.Municipio.toString() === filtroSelect2) &&
            (filtroSelect3 === '' || row.Zona.toString() === filtroSelect3)
    );

    return(
        <>
            <Box padding={2} display="grid" height="700px" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" alignItems="center"  >
                <Box sx={{ gridColumn: { md: "span 12", xs: "span 12" }, boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)", backgroundColor: "#ffff", borderRadius: "10px", height: "100%" }} >
                    <div style={{ width: '100%', height: '70%', borderRadius: "10px" }}>
                        <Box sx={{ backgroundColor: "#DB473C", padding: 1 }} display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" alignItems="center"  >
                            <Box sx={{ gridColumn: "span 12", textAlign: "center" }} >
                                <Typography color="white" fontSize={25} fontFamily="Anton" >ZONAS DE RADIODIFUSIÓN</Typography>
                            </Box>
                        </Box>
                        <Box padding={1} display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" alignItems="center"  >
                            <Box sx={{ gridColumn: { md: "span 3", xs: "span 4" }, textAlign: "center" }} >
                                <FormControl size="small" style={{ marginBottom: 10 }} fullWidth variant="standard">
                                    <InputLabel id="servicio-label">Filtrar por Departamento</InputLabel>
                                    <Select
                                        labelId="servicio-label"
                                        id="servicio-select"
                                        value={filtroSelect}
                                        onChange={handleFiltroSelectChange}
                                    >
                                        <MenuItem value="TODOS">Todos</MenuItem>
                                        <MenuItem value="ATLÁNTIDA">ATLÁNTIDA</MenuItem>
                                        <MenuItem value="COLÓN">COLÓN</MenuItem>
                                        <MenuItem value="COMAYAGUA">COMAYAGUA</MenuItem>
                                        <MenuItem value="COPÁN">COPÁN</MenuItem>
                                        <MenuItem value="CORTÉS">CORTÉS</MenuItem>
                                        <MenuItem value="CHOLUTECA">CHOLUTECA</MenuItem>
                                        <MenuItem value="EL PARAÍSO">EL PARAÍSO</MenuItem>
                                        <MenuItem value="FRANCISCO MORAZÁN">FRANCISCO MORAZÁN</MenuItem>
                                        <MenuItem value="GRACIAS A DIOS">GRACIAS A DIOS</MenuItem>
                                        <MenuItem value="INTIBUCÁ">INTIBUCÁ</MenuItem>
                                        <MenuItem value="ISLAS DE LA BAHÍA">ISLAS DE LA BAHÍA</MenuItem>
                                        <MenuItem value="LA PAZ">LA PAZ</MenuItem>
                                        <MenuItem value="LEMPIRA">LEMPIRA</MenuItem>
                                        <MenuItem value="OCOTEPEQUE">OCOTEPEQUE</MenuItem>
                                        <MenuItem value="OLANCHO">OLANCHO</MenuItem>
                                        <MenuItem value="SANTA BÁRBARA">SANTA BÁRBARA</MenuItem>
                                        <MenuItem value="VALLE">VALLE</MenuItem>
                                        <MenuItem value="YORO">YORO</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box sx={{ gridColumn: { md: "span 3", xs: "span 4" }, textAlign: "center" }} >
                            <FormControl size="small" style={{ marginBottom: 10 }} fullWidth variant="standard">
                                    <InputLabel id="servicio-label">Filtrar por Municipio</InputLabel>
                                    <Select
                                        labelId="servicio-label"
                                        id="servicio-select"
                                        value={filtroSelect2}
                                        onChange={handleFiltroSelectChange2}
                                    >
                                        <MenuItem value="">Todos</MenuItem>
                                       {
                                            municipios.map((municipio) =>(
                                                <MenuItem key={municipio.Id} value={municipio.Municipio}>{municipio.label}</MenuItem>
                                            ))
                                        }
                                       
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box sx={{ gridColumn: { md: "span 3", xs: "span 4" }, textAlign: "center" }} >
                            <FormControl size="small" style={{ marginBottom: 10 }} fullWidth variant="standard">
                                    <InputLabel id="servicio-label">Filtrar por Zona</InputLabel>
                                    <Select
                                        labelId="servicio-label"
                                        id="servicio-select"
                                        value={filtroSelect3}
                                        onChange={handleFiltroSelectChange3}
                                    >
                                        <MenuItem value="">Todos</MenuItem>
                                        <MenuItem value="1">Zona 1</MenuItem>
                                        <MenuItem value="2">Zona 2</MenuItem>
                                        <MenuItem value="3">Zona 3</MenuItem>
                                        <MenuItem value="4">Zona 4</MenuItem>
                                        <MenuItem value="5">Zona 5</MenuItem>
                                        <MenuItem value="6">Zona 6</MenuItem>
                                        <MenuItem value="7">Zona 7</MenuItem>
                                        <MenuItem value="8">Zona 8</MenuItem>
                                        <MenuItem value="9">Zona 9</MenuItem>
                                        <MenuItem value="10">Zona 10</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                        <Box padding={1} display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" alignItems="center"  >
                            <Box sx={{ gridColumn: "span 12", textAlign: "center" }} >
                                <DataGrid
                                    rowHeight={20}
                                    disableRowSelectionOnClick={true}
                                    rows={filteredRows}
                                    columns={columns}
                                    getRowId={rows => rows.Id}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 20,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[20]}
                                    components={{
                                        Toolbar: GridToolbar, // Utiliza el toolbar predeterminado de Material-UI
                                    }}
                                    localeText={{ toolbarFilters: 'Buscar', toolbarDensity: 'Vista',  noRowsLabel: "" }}
                                    sx={{
                                        '.MuiDataGrid-columnHeaderTitle': {
                                            fontWeight: 'bold',
                                          },
                                      }}
                                />
                            </Box>
                        </Box>
                    </div>
                    
                </Box>
               
            </Box>
            <br/>
            </>
    )
}

export default ZonasRadiodifusion