import React from "react";
import { Routes, Route } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import OperadoresActivos from "./paginaweb/OperadoresActivos";
import OperadoresAudioVisual from "./paginaweb/OperadoresAudioVisual";
import OperadoresTelevision from "./paginaweb/OperadoresTelevision";
import ZonasRadiodifusion from "./paginaweb/ZonasRadiodifusion";
import Topbar from "./static/topbar";
import Footer from "./static/footer";

function App() {
  return (
    <>
      <CssBaseline />
      <div className="App" style={{ backgroundColor: '#88CFE0', display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <Topbar setIsSidebar={true} />
        <main className="content">
          <Routes>
            <Route path="/" element={<OperadoresAudioVisual />} />
            <Route path="/OperadoresActivos" element={<OperadoresActivos />} />
            <Route path="/Audiovisual" element={<OperadoresAudioVisual />} />
            <Route path="/Television" element={<OperadoresTelevision />} />
            <Route path="/Zonas" element={<ZonasRadiodifusion />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </>
  )
}

export default App;
