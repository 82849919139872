import React, { useState, useEffect } from "react";
import { Box, Typography, Chip, Stack, TextField, Tooltip } from '@mui/material'
import { DataGrid, GridToolbar, GridActionsCellItem } from '@mui/x-data-grid'
import PlaceIcon from '@mui/icons-material/Place';
import 'animate.css'
import HondurasMap from '../svg/audioVisual'
import axios from 'axios';
import flecha1 from '../img/flecha1.png'
import flecha2 from '../img/flecha2.png'

const direccionConsultas = 'https://api-mapath.conatel.gob.hn/api/DatosAudioVisual'
// const direccionConsultas = 'http://localhost:5000/api/DatosAudioVisual'



const OperadoresAudioVisual = () => {
    const [departamento, setDepartamento] = useState("")
    const [cobertura, setCobertura] = useState([])
    const [listadoOperadores, setListadoOperadores] = useState([{
        "Canal": "",
        "Comunidad": "",
        "Estacion": "",
        "Id": "0",
        "Operador": ""
    }])

    const [filtroTexto, setFiltroTexto] = useState('');

    const handleFiltroTextoChange = (event) => {
        setFiltroTexto(event.target.value);
    };

    const filteredRows = listadoOperadores.filter(
        (row) =>
        (filtroTexto === '' ||
            (row.Operador && row.Operador.toLowerCase().includes(filtroTexto.toLowerCase())) ||
            (row.Denominacion && row.Denominacion.toLowerCase().includes(filtroTexto.toLowerCase()))
        )
    );

    useEffect(() => {
        if (departamento !== "") {
            const ListadoOperadoresAudioVisual = async () => {
                const values = {
                    'depto': departamento
                }
                const Datos = await axios.post(direccionConsultas + '/ListadoOperadoresAudioVisual', values)
                setListadoOperadores(Datos.data)
            }

            ListadoOperadoresAudioVisual()
        }
    }, [departamento])

    const columns = [
        {
            field: 'Operador',
            headerName: 'Nombre de empresa',
            flex: 2,
            headerAlign: 'center',
            align: 'left',
            className: 'Operador',
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'Denominacion',
            headerName: 'Denominación Comercial',
            flex: 2,
            headerAlign: 'center',
            align: 'left',
            className: 'Operador',
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'action',
            renderHeader: () => (
                <Tooltip title="Da click al icono para mostrar mas zonas de cobertura.">
                    <strong>Cobertura</strong>
                </Tooltip>
            ),
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            disableClickEventBubbling: true,
            renderCell: (params) => {
                const onClick = (e) => {
                const currentRow = params.row;
                   setCobertura(currentRow.DepartamentoCobertura.split(', '))
                   console.table(currentRow.DepartamentoCobertura.split(', '))
                };
                if(listadoOperadores[0].Id !== "0"){
                return (
                    <GridActionsCellItem onClick={onClick} icon={<PlaceIcon />} label="" style={{
                        padding: "0px",
                        color: "#DB473C",
                    }}></GridActionsCellItem>
                );
            }else{
                return(<></>)
            }
            },
        }
    ]

    return (
        <>
            <Box padding={2} display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" alignItems="center"  >
                <Box sx={{ gridColumn: { md: "span 7", xs: "span 12" } }}  >
                    <Box padding={2} display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" alignItems="center"  >
                        <Box sx={{ gridColumn: "span 12" }} >
                            <Box display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" alignItems="center"  >
                                <Box sx={{ gridColumn: "span 12", textAlign: "center" }} >
                                    {/* <Typography color="white" fontSize={24} fontFamily="Anton" >Pasos para consultar</Typography>
                                    <hr /> */}
                                </Box>
                            </Box>
                            <Box padding={2} display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" alignItems="center"  >
                                <Box sx={{ gridColumn: "span 12" }} >
                                    <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" >
                                        <Chip
                                            // avatar={<Avatar alt="Natacha" src={paso1} />}
                                            label="SELECCIONA EL DEPARTAMENTO"
                                            variant="outlined"
                                            sx={{ fontSize: { md: "0.8em", xs: "0.35em" }, backgroundColor: '#ffff', fontWeight: "bold" }}
                                            className="animate__animated animate__pulse animate__infinite"
                                        />
                                        {/* <Chip
                                            avatar={<Avatar alt="Natacha" src={paso2} />}
                                            label="VISUALIZA LOS RESULTADOS"
                                            variant="outlined"
                                            sx={{ fontSize: { md: "0.8em", xs: "0.35em", }, backgroundColor: '#ffff', fontWeight: "bold" }}
                                            className="animate__animated animate__pulse animate__infinite"
                                        /> */}
                                    </Stack>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{ gridColumn: "span 12", textAlign: "center", display: { md: 'none', lg: 'none', xs: 'block' } }}>
                            <Box display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" alignItems="center">
                                <Box sx={{ gridColumn: "span 4", textAlign: "right" }}>
                                    <img src={flecha1} alt="Logo de la empresa" style={{ verticalAlign: 'middle', maxWidth: '15%', height: 'auto' }} />
                                </Box>
                                <Box sx={{ gridColumn: "span 4", textAlign: "center" }}>
                                    <Typography color="white" fontSize={15} fontFamily="Anton" style={{ display: 'inline-block', verticalAlign: 'middle' }}>Explora el mapa</Typography>
                                </Box>
                                <Box sx={{ gridColumn: "span 4", textAlign: "left" }}>
                                    <img src={flecha2} alt="Logo de la empresa" style={{ verticalAlign: 'middle', maxWidth: '15%', height: 'auto' }} />
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{ gridColumn: "span 12", display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'auto' }} >
                            <div style={{ width: '100%', maxWidth: '800px', height: '10%', borderRadius: "10px" }}>
                                <HondurasMap setDepartamento={setDepartamento} cobertura={cobertura} setCobertura={setCobertura} >
                                </HondurasMap>
                            </div>
                        </Box>

                    </Box>
                </Box>
                <Box sx={{ gridColumn: { md: "span 5", xs: "span 12" }, boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)", backgroundColor: "#ffff", borderRadius: "10px", height: "100%" }} >
                    <div style={{ width: '100%', height: '70%', borderRadius: "10px" }}>
                        <Box sx={{ backgroundColor: "#DB473C", padding: 1 }} display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" alignItems="center"  >
                            <Box sx={{ gridColumn: "span 12", textAlign: "center" }} >
                                <Typography color="white" fontSize={25} fontFamily="Anton" >OPERADORES AUDIOVISUALES AUTORIZADOS</Typography>
                            </Box>
                        </Box>
                        <Box padding={1} display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" alignItems="center"  >
                            <Box sx={{ gridColumn: "span 12", textAlign: "center" }} >
                                <TextField
                                    fullWidth
                                    label="Buscar"
                                    value={filtroTexto}
                                    onChange={handleFiltroTextoChange}
                                    variant="standard"
                                    size="small"
                                    style={{ marginBottom: 10 }}
                                />
                            </Box>
                        </Box>
                        <Box padding={1} display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" alignItems="center"  >
                            <Box sx={{ gridColumn: "span 12", textAlign: "center" }} >
                                <DataGrid
                                    rowHeight={20}
                                    disableRowSelectionOnClick={true}
                                    rows={filteredRows}
                                    columns={columns}
                                    getRowId={rows => rows.Id}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 15,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[15]}
                                    components={{
                                        Toolbar: GridToolbar, // Utiliza el toolbar predeterminado de Material-UI
                                    }}
                                    localeText={{ toolbarFilters: 'Buscar', toolbarDensity: 'Vista', noRowsLabel: "" }}
                                    sx={{
                                        '.MuiDataGrid-columnHeaderTitle': {
                                            fontWeight: 'bold',
                                          },
                                      }}
                                />

                            </Box>
                            {/* <Box sx={{ gridColumn: "span 12", textAlign: "center" }} >
                            <Modal setOpen={setOpen} Open={Open} listadoMunicipios={listadoMunicipios} />
                            </Box> */}
                        </Box>
                        {
                                filteredRows.length === 0 ?
                                    <Box padding={1} display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" alignItems="center"  >
                                        <Box sx={{ gridColumn: "span 12", textAlign: "center" }} >
                                            <span style={{ fontSize: "20px" }}>Este operador no está registrado ante CONATEL.</span> <br />
                                            Llama gratis al 185 para reportar a este operador irregular o repórtalo al correo: proteccionusuario@conatel.gob.hn<br />
                                            <span style={{fontSize: "20px"}}>¡COMBATAMOS LA PIRATERIA!</span>
                                        </Box>
                                    </Box>
                                    :
                                    <></>
                            }
                    </div>
                </Box>
            </Box>
        </>
    )
}

export default OperadoresAudioVisual